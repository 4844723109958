<template>
  <van-tabs v-if="utils.checkSystemType(constants.systemType.gzbSystem)" v-model="active">
    <van-tab :title="this.$route.query.put === 'put' ? '添加开票结果' : '发票记录'">
      <div class="van-main">
        <div class="van-common-detail">
          <van-cell-group>
            <van-cell v-if="utils.checkSystemType(constants.systemType.nongtouSystem)" title="发票种类：" :value="utils.statusFormat(appForm.distinguish, 'Distinguish')" />
            <van-cell title="关联订单：" :value="utils.isEffectiveCommon(appForm.orderId)" />
            <van-cell title="订单编号：" :value="utils.isEffectiveCommon(appForm.orderNo)" />
            <van-cell title="开票方类型：" :value="utils.statusFormat(String(appForm.invoiceSendType), 'EnterpriseOrgType')" />
            <van-cell title="开票方：" :value="utils.isEffectiveCommon(appForm.invoiceSendName)" />
            <van-cell title="收票方类型：" :value="utils.statusFormat(String(appForm.invoiceReceiveType), 'EnterpriseOrgType')" />
            <van-cell title="收票方：" :value="utils.isEffectiveCommon(appForm.invoiceReceiveName)" />
            <van-cell title="开票金额：" :value="utils.moneyFormat(appForm.invoiceMoney)" />
            <van-cell title="开票类型：" :value="utils.statusFormat(appForm.invoiceType, 'ExpenseType')" />
            <van-cell title="开票品名：" :value="utils.isEffectiveCommon(appForm.productName)" />
            <van-cell title="开票日期：" :value="utils.isEffectiveCommon(appForm.invoiceDate)" />
            <van-cell title="备注：" :value="utils.isEffectiveCommon(appForm.remark)" />
            <file-list :file-list="fileList" title="发票原件：" />
          </van-cell-group>
        </div>
        <van-row class="fixed-btn-bottom" gutter="20">
          <van-button type="info" color="#1373CC" block text="返回" @click="$router.back()" />
        </van-row>
      </div>
      <van-tab title="审批意见" v-if="utils.checkSystemType(constants.systemType.gzbSystem) && $route.query.type != 'invoice'">
        <workflow-audit :task-id="$route.query.taskId" page-key="ht_con_seal" :business-key="appForm.sealId" :proc-inst-id="$route.query.procInstId" :view-mode="true" @confirmAudit="utils.backReturn($route.query.messageType)" />
      </van-tab>
    </van-tab>
  </van-tabs>
  <div v-else class="van-main stock-detail" style="padding-bottom: 40px;">
    <div class="area-title">
      <p class="title">
        发票记录
      </p>
    </div>
    <div class="van-main">
      <div class="van-common-detail">
        <van-cell-group>
          <van-cell v-if="utils.checkSystemType(constants.systemType.nongtouSystem)" title="发票种类：" :value="utils.statusFormat(appForm.distinguish, 'Distinguish')" />
          <van-cell title="关联订单：" :value="utils.isEffectiveCommon(appForm.orderId)" />
          <van-cell title="订单编号：" :value="utils.isEffectiveCommon(appForm.orderNo)" />
          <van-cell title="开票方类型：" :value="utils.statusFormat(String(appForm.invoiceSendType), 'EnterpriseOrgType')" />
          <van-cell title="开票方：" :value="utils.isEffectiveCommon(appForm.invoiceSendName)" />
          <van-cell title="收票方类型：" :value="utils.statusFormat(String(appForm.invoiceReceiveType), 'EnterpriseOrgType')" />
          <van-cell title="收票方：" :value="utils.isEffectiveCommon(appForm.invoiceReceiveName)" />
          <van-cell title="开票金额：" :value="utils.moneyFormat(appForm.invoiceMoney)" />
          <van-cell title="开票类型：" :value="utils.statusFormat(appForm.invoiceType, 'ExpenseType')" />
          <van-cell title="开票品名：" :value="utils.isEffectiveCommon(appForm.productName)" />
          <van-cell title="开票日期：" :value="utils.isEffectiveCommon(appForm.invoiceDate)" />
          <van-cell title="备注：" :value="utils.isEffectiveCommon(appForm.remark)" />
          <file-list :file-list="fileList" title="发票原件：" />
        </van-cell-group>
      </div>
      <van-row class="fixed-btn-bottom" gutter="20">
        <van-button type="info" color="#1373CC" block text="返回" @click="$router.back()" />
      </van-row>
    </div>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button, Field, Form, Col, Row, Tab, Tabs } from 'vant'
import fileList from '@/components/file-list'
import WorkflowAudit from '@/components/workflow-audit'
export default {
  components: {
    WorkflowAudit,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [Form.name]: Form,
    [Col.name]: Col,
    [Row.name]: Row,
    fileList
  },
  data () {
    return {
      appForm: {},
      fileList: [],
      active: 0
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail (tenderId) {
      this.api.contract.invoice.detail(this.$route.query.id).then(result => {
        this.appForm = result.data.value || {}
        this.fileList = result.data.value.fileDetailInfoVos
      })
    }
  }
}
</script>
  <style lang="less">
    .header-th{
      text-align: center;
      padding: 5px;
    }
  </style>
