var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.utils.checkSystemType(_vm.constants.systemType.gzbSystem)
    ? _c(
        "van-tabs",
        {
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c(
            "van-tab",
            {
              attrs: {
                title:
                  this.$route.query.put === "put" ? "添加开票结果" : "发票记录"
              }
            },
            [
              _c(
                "div",
                { staticClass: "van-main" },
                [
                  _c(
                    "div",
                    { staticClass: "van-common-detail" },
                    [
                      _c(
                        "van-cell-group",
                        [
                          _vm.utils.checkSystemType(
                            _vm.constants.systemType.nongtouSystem
                          )
                            ? _c("van-cell", {
                                attrs: {
                                  title: "发票种类：",
                                  value: _vm.utils.statusFormat(
                                    _vm.appForm.distinguish,
                                    "Distinguish"
                                  )
                                }
                              })
                            : _vm._e(),
                          _c("van-cell", {
                            attrs: {
                              title: "关联订单：",
                              value: _vm.utils.isEffectiveCommon(
                                _vm.appForm.orderId
                              )
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "订单编号：",
                              value: _vm.utils.isEffectiveCommon(
                                _vm.appForm.orderNo
                              )
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "开票方类型：",
                              value: _vm.utils.statusFormat(
                                String(_vm.appForm.invoiceSendType),
                                "EnterpriseOrgType"
                              )
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "开票方：",
                              value: _vm.utils.isEffectiveCommon(
                                _vm.appForm.invoiceSendName
                              )
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "收票方类型：",
                              value: _vm.utils.statusFormat(
                                String(_vm.appForm.invoiceReceiveType),
                                "EnterpriseOrgType"
                              )
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "收票方：",
                              value: _vm.utils.isEffectiveCommon(
                                _vm.appForm.invoiceReceiveName
                              )
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "开票金额：",
                              value: _vm.utils.moneyFormat(
                                _vm.appForm.invoiceMoney
                              )
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "开票类型：",
                              value: _vm.utils.statusFormat(
                                _vm.appForm.invoiceType,
                                "ExpenseType"
                              )
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "开票品名：",
                              value: _vm.utils.isEffectiveCommon(
                                _vm.appForm.productName
                              )
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "开票日期：",
                              value: _vm.utils.isEffectiveCommon(
                                _vm.appForm.invoiceDate
                              )
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "备注：",
                              value: _vm.utils.isEffectiveCommon(
                                _vm.appForm.remark
                              )
                            }
                          }),
                          _c("file-list", {
                            attrs: {
                              "file-list": _vm.fileList,
                              title: "发票原件："
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "van-row",
                    {
                      staticClass: "fixed-btn-bottom",
                      attrs: { gutter: "20" }
                    },
                    [
                      _c("van-button", {
                        attrs: {
                          type: "info",
                          color: "#1373CC",
                          block: "",
                          text: "返回"
                        },
                        on: {
                          click: function($event) {
                            return _vm.$router.back()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.utils.checkSystemType(_vm.constants.systemType.gzbSystem) &&
              _vm.$route.query.type != "invoice"
                ? _c(
                    "van-tab",
                    { attrs: { title: "审批意见" } },
                    [
                      _c("workflow-audit", {
                        attrs: {
                          "task-id": _vm.$route.query.taskId,
                          "page-key": "ht_con_seal",
                          "business-key": _vm.appForm.sealId,
                          "proc-inst-id": _vm.$route.query.procInstId,
                          "view-mode": true
                        },
                        on: {
                          confirmAudit: function($event) {
                            return _vm.utils.backReturn(
                              _vm.$route.query.messageType
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass: "van-main stock-detail",
          staticStyle: { "padding-bottom": "40px" }
        },
        [
          _c("div", { staticClass: "area-title" }, [
            _c("p", { staticClass: "title" }, [_vm._v(" 发票记录 ")])
          ]),
          _c(
            "div",
            { staticClass: "van-main" },
            [
              _c(
                "div",
                { staticClass: "van-common-detail" },
                [
                  _c(
                    "van-cell-group",
                    [
                      _vm.utils.checkSystemType(
                        _vm.constants.systemType.nongtouSystem
                      )
                        ? _c("van-cell", {
                            attrs: {
                              title: "发票种类：",
                              value: _vm.utils.statusFormat(
                                _vm.appForm.distinguish,
                                "Distinguish"
                              )
                            }
                          })
                        : _vm._e(),
                      _c("van-cell", {
                        attrs: {
                          title: "关联订单：",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.appForm.orderId
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "订单编号：",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.appForm.orderNo
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "开票方类型：",
                          value: _vm.utils.statusFormat(
                            String(_vm.appForm.invoiceSendType),
                            "EnterpriseOrgType"
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "开票方：",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.appForm.invoiceSendName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "收票方类型：",
                          value: _vm.utils.statusFormat(
                            String(_vm.appForm.invoiceReceiveType),
                            "EnterpriseOrgType"
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "收票方：",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.appForm.invoiceReceiveName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "开票金额：",
                          value: _vm.utils.moneyFormat(_vm.appForm.invoiceMoney)
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "开票类型：",
                          value: _vm.utils.statusFormat(
                            _vm.appForm.invoiceType,
                            "ExpenseType"
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "开票品名：",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.appForm.productName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "开票日期：",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.appForm.invoiceDate
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "备注：",
                          value: _vm.utils.isEffectiveCommon(_vm.appForm.remark)
                        }
                      }),
                      _c("file-list", {
                        attrs: {
                          "file-list": _vm.fileList,
                          title: "发票原件："
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "van-row",
                { staticClass: "fixed-btn-bottom", attrs: { gutter: "20" } },
                [
                  _c("van-button", {
                    attrs: {
                      type: "info",
                      color: "#1373CC",
                      block: "",
                      text: "返回"
                    },
                    on: {
                      click: function($event) {
                        return _vm.$router.back()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }